import { get } from 'lodash';
import {
  getUniqueSectionIdForFeedPage,
  getUniqueSectionIdForPostPage,
  getCoordinatesRelativeToStructure,
} from './sdk-utils';
import { OLD_BLOG_APP_PART, TOKEN } from './constants';
import { blogAppDefId } from '../../constants/apps';
import {
  ARCHIVE_WIDGET_ID,
  CATEGORY_MENU_WIDGET_ID,
  POST_LIST_WIDGET_ID,
  POST_WIDGET_ID,
  RSS_BUTTON_WIDGET_ID,
  BLOG_WIDGET_ID,
  TAG_CLOUD_WIDGET_ID,
} from '../../constants/widgets';
import retry from '../retry';
import getBox from './get-box';
import BlogWidgetBuilder from './blog-widget-builder';
import {
  LAYOUT_LIST,
  LAYOUT_PG_GRID,
  LAYOUT_SLIDER,
  LAYOUT_PG_SIDE_BY_SIDE,
  PG_LAYOUT_IMAGE_ALIGNMENT_OPTIONS,
  TAG_LAYOUTS,
  TAG_ALIGNMENTS,
  TAG_ORDERS,
} from '@wix/communities-blog-client-common';
import { RSS_BUTTON_BACKGROUNDS, RSS_BUTTON_COLORS, RSS_BUTTON_IMAGES } from './rss-button';
import { fetchCategoryId, fetchTagId } from './api';
import { trueToFalse, boolean } from './utils';

const columnsToPostSize = (value, width) => Number(width / value).toFixed();
const parseJson = (value) => {
  try {
    return JSON.parse(value);
  } catch (_) {}
};
const toSquare = ({ width = Infinity, height = Infinity } = {}) => {
  const min = Math.min(width, height);
  return { width: min, height: min };
};
const resolvePostFilters = async (serializedComponent, instance, dryRun) => {
  const getAppLogicParam = (component, param, path) => {
    const json = get(component, `data.appLogicParams.${param}.value`);
    return get(parseJson(json), path);
  };

  const categoryLabel = getAppLogicParam(serializedComponent, 'categoryNames', '[0]');
  const tagLabel = getAppLogicParam(serializedComponent, 'filter', 'tags');

  const [categoryId, tagId] = await Promise.all([
    categoryLabel && !dryRun ? retry(() => fetchCategoryId(instance, categoryLabel), 3) : Promise.resolve(),
    tagLabel ? retry(() => fetchTagId(instance, tagLabel), 3) : Promise.resolve(),
  ]);

  return { categoryId, tagId };
};

let state = {
  fontMap: undefined,
  styleMap: undefined,
  applicationId: undefined,
  instance: undefined,
};

export const resetState = () => (state = {});

const mapComponent = async (serializedComponent, type, { sdk, dryRun }) => {
  const componentWidth = serializedComponent.layout.width;

  if (!state.applicationId) {
    const blogData = await sdk.tpa.app.getDataByAppDefId(TOKEN, blogAppDefId);
    state.applicationId = String(blogData.applicationId);
  }

  if (!state.fontMap) {
    const [fontMap, styleMap] = await Promise.all([sdk.theme.fonts.getMap(), sdk.theme.styles.getAll()]);
    state.fontMap = fontMap;
    state.styleMap = styleMap;
  }

  if (!state.instance) {
    state.instance = await sdk.document.info.getAppInstance(TOKEN);
  }

  const { fontMap, styleMap, applicationId, instance } = state;

  switch (type) {
    case OLD_BLOG_APP_PART.CUSTOM_FEED: {
      const { categoryId, tagId } = await resolvePostFilters(serializedComponent, instance, dryRun);
      // prettier-ignore
      const builder = new BlogWidgetBuilder(serializedComponent, {
        widgetId: POST_LIST_WIDGET_ID,
        applicationId,
        fontMap,
        styleMap,
      })
      .mapViewName('param_number_layout-post-list-layoutType', {
        defaultValue: LAYOUT_LIST,
        formatter: String,
        valueMap: {
          MediaLeft: LAYOUT_LIST,
          MediaRight: LAYOUT_LIST,
          MediaZigzag: LAYOUT_LIST,
          MediaTop: LAYOUT_PG_GRID,
          MediaBottom: LAYOUT_PG_GRID,
          Masonry: LAYOUT_PG_GRID,
        },
      })
        .mapLogicParam('filter', 'param_boolean_postListWidgetIsFeatured', {
          formatter: (filter) => boolean(get(parseJson(filter), 'featured', 'false')),
        })
        .mapValueParam('$(viewName)/columnBlog/comp.columns', 'param_number_layout-post-list-pg-grid-layoutPostSize', { minValue: 292, defaultValue: Number(componentWidth / 2).toFixed(), formatter: (value) => columnsToPostSize(value, componentWidth) })
        .mapValueParam('$(viewName)/vars/itemsPerPage', 'param_number_postListWidgetEntityCount', { defaultValue: 3, formatter: Number })
        .mapValueParam('$(viewName)/vars/itemsPerPage', 'param_number_postListWidgetPostsPerPage', { defaultValue: 3, formatter: Number })
        .mapValueParam('$(viewName)/vars/pageNavigationType', 'param_number_postListWidgetPaginationType', {
          defaultValue: 0,
          formatter: Number,
          valueMap: {
            pagination: 1,
          }
        })
        .mapValueParam('$(viewName)/media/comp.hidden', 'param_boolean_blog-isCoverImageEnabled', {
          defaultValue: true,
          formatter: trueToFalse,
        })
        .setValueParam('param_boolean_blog-isFeedDesignPropsMigrated', true)
        .setValueParam('param_boolean_blog-isAuthorNameEnabled', false)
        .setValueParam('param_boolean_blog-isViewCountEnabled', false)
        .setValueParam('param_boolean_blog-isCommentCountEnabled', false)
        .setValueParam('param_boolean_blog-isLikeCountEnabled', false)
        .setValueParam('param_boolean_blog-isReadingTimeEnabled', false)
        .setValueParam('param_boolean_blog-isMoreOptionsMenuEnabled', false)
        .setValueParam('param_boolean_socialSharing-isEnabled', false)
        .mapFont(['param_font_post-post-list-titleFont', 'param_font_post-list-post-list-titleFont', 'param_font_post-pg-grid-post-list-titleFont', 'param_font_post-slider-post-list-titleFont'])
          .mapTheme('$(viewName)/title/comp.style')
          .mapValue('$(viewName)/title/comp.fontFamily', 'family', { defaultValue: 'proxima-n-w01-reg' })
          .mapValue('$(viewName)/title/comp.fontSize', 'size', { minValue: 6, maxValue: 215, defaultValue: 22, formatter: Number })
          .mapValue('$(viewName)/title/comp.bold', 'style.bold', { defaultValue: false, formatter: boolean })
          .mapValue('$(viewName)/title/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
          .mapValue('$(viewName)/title/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
          .value()
        .mapFont(['param_font_post-post-list-descriptionFont', 'param_font_post-list-post-list-descriptionFont', 'param_font_post-pg-grid-post-list-descriptionFont', 'param_font_post-slider-post-list-titleFont'])
          .mapTheme('$(viewName)/text/comp.style')
          .mapValue('$(viewName)/text/comp.fontFamily', 'family', { defaultValue: 'proxima-n-w01-reg' })
          .setValue('size', 15)
          .mapValue('$(viewName)/text/comp.bold', 'style.bold', { defaultValue: false, formatter: boolean })
          .mapValue('$(viewName)/text/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
          .mapValue('$(viewName)/text/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
          .value()
        .mapFontColor('$(viewName)/title/$(key)', 'param_color_post-post-list-titleColor')
        .mapFontColor('$(viewName)/text/$(key)', 'param_color_post-post-list-descriptionColor')
        .setColor('param_color_post-appBackgroundColor', 'color_11', 0)
        .mapThemeColor('$(viewName)/def_2/comp.style/bg', 'param_color_post-post-list-backgroundColor', { defaultValue: ['color_11', 1] })
        .mapThemeColor('$(viewName)/def_2/comp.style/brd', 'param_color_post-post-list-borderColor', { defaultValue: ['color_15', 1] })
        .mapThemeParam('$(viewName)/def_2/comp.style/brw', 'param_number_post-post-list-borderWidth', { defaultValue: 0, formatter: parseInt })
        .setValueParam('param_number_layout-post-list-pg-grid-margins', 0)
        .setValueParam('param_number_layout-post-list-list-margins', 0)
        .setValueParam('param_number_pagination-post-list-type', 1)
        .setValueParam('param_number_layout-post-list-list-layoutPostSize', 1000)
        .setValueParam('param_number_layout-post-list-pg-grid-layoutPostSize', 1000)
        .setValueParam('param_number_layout-post-list-pg-grid-imageCropType', 1)
        .setValueParam('param_number_layout-post-list-list-imageCropType', 1)
        .when('data.viewName', ['MediaLeft', 'MediaZigzag'])
          .setValueParam('param_number_layout-post-list-list-imageAlignment', PG_LAYOUT_IMAGE_ALIGNMENT_OPTIONS.LEFT_ALIGN)
          .when('props.direction', 'rtl')
            .setValueParam('param_number_layout-post-list-list-imageAlignment', PG_LAYOUT_IMAGE_ALIGNMENT_OPTIONS.RIGHT_ALIGN)
            .value()
          .value()
        .when('data.viewName', ['MediaRight'])
          .setValueParam('param_number_layout-post-list-list-imageAlignment', PG_LAYOUT_IMAGE_ALIGNMENT_OPTIONS.RIGHT_ALIGN)
          .when('props.direction', 'rtl')
            .setValueParam('param_number_layout-post-list-list-imageAlignment', PG_LAYOUT_IMAGE_ALIGNMENT_OPTIONS.LEFT_ALIGN)
            .value()
          .value()
        .when('data.viewName', ['MediaLeft', 'MediaRight', 'MediaZigzag'])
          .setValueParam('param_number_layout-post-list-list-sidesPadding', 48)
          .mapValueParam({ view: '$(viewName)', fieldId: 'PostSpace', key: 'comp.size', format: '' }, 'param_number_layout-post-list-list-layoutSpacing', {
            maxValue: 100,
            formatter: Number,
          })
          .value()
        .when('data.viewName', ['Masonry'])
          .mapValueParam('$(viewName)/columnBlog/comp.columns', 'param_number_layout-post-list-pg-grid-layoutPostSize', { minValue: 292, defaultValue: Number(componentWidth / 2).toFixed(), formatter: (value) => columnsToPostSize(value, componentWidth) })
          .mapValueParam('$(viewName)/columnBlog/comp.horizontalGap', 'param_number_layout-post-list-pg-grid-layoutSpacing', { minValue: 10, maxValue: 100, defaultValue: 20, formatter: Number })
          .value()
        .when('data.viewName', ['Masonry', 'MediaTop', 'MediaBottom'])
          .mapValueParam('$(viewName)/text/comp.hidden', 'param_boolean_blog-isPostDescriptionEnabled', { defaultValue: true, formatter: trueToFalse })
          .value()
        .when('data.viewName', ['MediaTop', 'MediaBottom'])
          .mapValueParam({ view: '$(viewName)', fieldId: 'PostSpace', key: 'comp.size', format: '' }, 'param_number_layout-post-list-pg-grid-layoutSpacing', {
            minValue: 10,
            maxValue: 100,
            formatter: Number,
          })
          .value()
        .when('data.viewName', ['MediaTop', 'MediaBottom', 'Masonry'])
          .mapContentHeight('param_number_layout-post-list-pg-grid-contentHeight')
          .value()
        .setTpaData('categoryId', categoryId)
        .setTpaData('tagId', tagId)
        .setValueParam('param_boolean_blog-isPostPublishDateEnabled', false);

      return { componentDefinition: builder.build() };
    }
    case OLD_BLOG_APP_PART.FEED: {
      const coordinates = await getCoordinatesRelativeToStructure(sdk, { id: serializedComponent.id, type: 'DESKTOP' });
      // prettier-ignore
      const builder = new BlogWidgetBuilder(serializedComponent, {
        widgetId: BLOG_WIDGET_ID,
        applicationId,
        fontMap,
        styleMap,
        widgetType: BlogWidgetBuilder.TPASection,
      })
        .mapViewName('param_number_layout-$(section)-layoutType', {
          defaultValue: LAYOUT_PG_SIDE_BY_SIDE,
          formatter: String,
          valueMap: {
            MediaLeftPage: LAYOUT_PG_SIDE_BY_SIDE,
            MediaRightPage: LAYOUT_PG_SIDE_BY_SIDE,
            MediaZigzagPage: LAYOUT_PG_SIDE_BY_SIDE,
            MediaTopPage: LAYOUT_PG_GRID,
            MediaBottomPage: LAYOUT_PG_GRID,
            MasonryPage: LAYOUT_PG_GRID,
          },
        })
        .mapValueParam('$(viewName)/text/comp.hidden', 'param_boolean_blog-isPostDescriptionEnabled', { defaultValue: true, formatter: trueToFalse })
        .mapValueParam('$(viewName)/columnBlog/comp.columns', 'param_number_layout-$(section)-pg-grid-layoutPostSize', { minValue: 292, defaultValue: Number(componentWidth / 2).toFixed(), formatter: (value) => columnsToPostSize(value, componentWidth) })
        .mapValueParam('$(viewName)/columnBlog/comp.horizontalGap', 'param_number_layout-$(section)-pg-grid-layoutSpacing', { maxValue: 100, formatter: Number })
        .mapValueParam('$(viewName)/vars/itemsPerPage', 'param_number_pagination-$(section)-pageSize', { defaultValue: 10, formatter: Number })
        .mapValueParam('$(viewName)/media/comp.hidden', 'param_boolean_blog-isCoverImageEnabled', {
          defaultValue: true,
          formatter: trueToFalse,
        })
        .setValueParam('param_boolean_blog-isFeedDesignPropsMigrated', true)
        .setValueParam('param_boolean_blog-isAuthorNameEnabled', false)
        .setValueParam('param_boolean_blog-isViewCountEnabled', false)
        .setValueParam('param_boolean_blog-isCommentCountEnabled', false)
        .setValueParam('param_boolean_blog-isLikeCountEnabled', false)
        .setValueParam('param_boolean_blog-isReadingTimeEnabled', false)
        .setValueParam('param_boolean_blog-isMoreOptionsMenuEnabled', false)
        .setValueParam('param_boolean_socialSharing-isEnabled', false)
        .setValueParam('param_boolean_blogMenu-isEnabled', false)
        .mapFont(['param_font_post-$(section)-titleFont', 'param_font_post-pg-side-by-side-$(section)-titleFont', 'param_font_post-pg-grid-$(section)-titleFont'])
          .mapTheme('$(viewName)/title/comp.style')
          .mapValue('$(viewName)/title/comp.fontFamily', 'family')
          .mapValue('$(viewName)/title/comp.fontSize', 'size', { minValue: 6, maxValue: 215, formatter: Number })
          .mapValue('$(viewName)/title/comp.bold', 'style.bold', { defaultValue: false, formatter: boolean })
          .mapValue('$(viewName)/title/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
          .mapValue('$(viewName)/title/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
          .value()
        .mapFont(['param_font_post-$(section)-descriptionFont', 'param_font_post-pg-side-by-side-$(section)-descriptionFont', 'param_font_post-pg-grid-$(section)-descriptionFont'])
          .mapTheme('$(viewName)/text/comp.style', { size: { minValue: 6, maxValue: 18 } })
          .mapValue('$(viewName)/text/comp.fontFamily', 'family')
          .mapValue('$(viewName)/text/comp.fontSize', 'size', { minValue: 6, maxValue: 18, formatter: Number })
          .mapValue('$(viewName)/text/comp.bold', 'style.bold', { defaultValue: false, formatter: boolean })
          .mapValue('$(viewName)/text/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
          .mapValue('$(viewName)/text/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
          .value()
        .mapFontColor('$(viewName)/title/$(key)', 'param_color_post-$(section)-titleColor')
        .mapFontColor('$(viewName)/title/$(key)', 'param_color_post-$(section)-linkHashtagColor')
        .mapFontColor('$(viewName)/text/$(key)', 'param_color_post-$(section)-descriptionColor')
        .setColor('param_color_post-appBackgroundColor', 'color_11', 0)
        .mapThemeColor('$(viewName)/def_2/comp.style/bg', 'param_color_post-$(section)-backgroundColor', { defaultValue: ['color_11', 1] })
        .mapThemeColor('$(viewName)/def_2/comp.style/brd', 'param_color_post-$(section)-borderColor', { defaultValue: ['color_15', 1] })
        .mapThemeParam('$(viewName)/def_2/comp.style/brw', 'param_number_post-$(section)-borderWidth', { defaultValue: 0, formatter: parseInt })
        .setValueParam('param_number_layout-$(section)-pg-grid-margins', 0)
        .setValueParam('param_number_layout-$(section)-pg-side-by-side-margins', 0)
        .setValueParam('param_number_pagination-$(section)-type', 1)
        .setValueParam('param_number_layout-$(section)-pg-grid-imageCropType', 1)
        .setValueParam('param_number_layout-$(section)-side-by-side-imageCropType', 1)
        .when('data.viewName', ['MediaLeftPage', 'MediaRightPage', 'MediaZigzagPage'])
          .setValueParam('param_number_layout-$(section)-pg-side-by-side-sidesPadding', 24)
          .mapValueParam({ view: '$(viewName)', fieldId: 'PostSpace', key: 'comp.size', format: '' }, 'param_number_layout-$(section)-pg-side-by-side-layoutSpacing', {
            maxValue: 100,
            formatter: Number,
          })
          .value()
        .when('data.viewName', ['MediaLeftPage', 'MediaZigzagPage'])
          .setValueParam('param_number_layout-$(section)-pg-side-by-side-imageAlignment', PG_LAYOUT_IMAGE_ALIGNMENT_OPTIONS.LEFT_ALIGN)
          .when('props.direction', 'rtl')
            .setValueParam('param_number_layout-$(section)-pg-side-by-side-imageAlignment', PG_LAYOUT_IMAGE_ALIGNMENT_OPTIONS.RIGHT_ALIGN)
            .value()
          .value()
        .when('data.viewName', 'MediaRightPage')
          .setValueParam('param_number_layout-$(section)-pg-side-by-side-imageAlignment', PG_LAYOUT_IMAGE_ALIGNMENT_OPTIONS.RIGHT_ALIGN)
          .when('props.direction', 'rtl')
            .setValueParam('param_number_layout-$(section)-pg-side-by-side-imageAlignment', PG_LAYOUT_IMAGE_ALIGNMENT_OPTIONS.LEFT_ALIGN)
            .value()
          .value()
        .when('data.viewName', ['MediaTopPage', 'MediaBottomPage'])
          .setValueParam('param_number_layout-$(section)-pg-grid-layoutPostSize', 1000)
          .mapValueParam({ view: '$(viewName)', fieldId: 'PostSpace', key: 'comp.size', format: '' }, 'param_number_layout-$(section)-pg-medium-layoutSpacing', {
            maxValue: 100,
            formatter: Number,
          })
          .value()
        .when('data.viewName', ['MediaTopPage', 'MediaBottomPage', 'MasonryPage'])
          .mapContentHeight('param_number_layout-$(section)-pg-grid-contentHeight')
          .value()
        .setCoordinates(coordinates)
        .setValueParam('param_boolean_blog-isPostPublishDateEnabled', false);

      return {
        componentDefinition: builder.build(),
        props: { customId: await getUniqueSectionIdForFeedPage(sdk) },
      };
    }
    case OLD_BLOG_APP_PART.POSTS_GALLERY: {
      const builder = new BlogWidgetBuilder(serializedComponent, {
        widgetId: POST_LIST_WIDGET_ID,
        applicationId,
        fontMap,
        styleMap,
      });

      const columns = builder.resolveValue('$(viewName)/postsGallery/comp.columns', {
        defaultValue: 2,
        formatter: Number,
      });
      const rows = builder.resolveValue('$(viewName)/postsGallery/comp.rows', {
        defaultValue: 1,
        formatter: Number,
      });
      let numberOfPosts = Number(get(serializedComponent, `data.appLogicParams.limit.value`, 10));

      const shouldMapToSlideshow = columns === 1 && rows === 1 && numberOfPosts > 1;
      if (!shouldMapToSlideshow) {
        numberOfPosts = Math.min(numberOfPosts, columns * rows);
      }

      builder.whenMapsToSlideshow = function () {
        const branch = new BlogWidgetBuilder(this.oldBlogComponent, this.props);
        branch.parent = this;
        branch.shouldMergeParent = shouldMapToSlideshow;
        return branch;
      };

      const { categoryId, tagId } = await resolvePostFilters(serializedComponent, instance, dryRun);
      // prettier-ignore
      builder
        .setValueParam('param_number_layout-post-list-layoutType', String(LAYOUT_PG_GRID))
        .mapValueParam('$(viewName)/media/comp.hidden', 'param_boolean_blog-isCoverImageEnabled', { defaultValue: true, formatter: trueToFalse })
        .mapValueParam('$(viewName)/postsGallery/comp.columns', 'param_number_layout-post-list-pg-grid-layoutPostSize', { minValue: 150, defaultValue: Number(componentWidth / 2).toFixed(), formatter: (value) => columnsToPostSize(value, componentWidth) })
        .mapContentHeight('param_number_layout-post-list-pg-grid-contentHeight', false)
        .setValueParam('param_number_layout-post-list-pg-grid-imageCropType', 1)
        .whenMapsToSlideshow()
          .setValueParam('param_number_layout-post-list-layoutType', String(LAYOUT_SLIDER))
          .setValueParam('param_number_layout-post-list-slider-margins', 0)
          .mapValueParam('$(viewName)/postsGallery/comp.autoplay', 'param_boolean_postListWidgetSliderAutoSlide', { defaultValue: false, formatter: boolean })
          .mapValueParam('$(viewName)/postsGallery/comp.autoplayInterval', 'param_number_postListWidgetSliderPauseTime', { minValue: 1, maxValue: 10, defaultValue: 3, formatter: Number })
          .mapFont('param_font_post-slider-post-list-titleFont')
            .mapTheme('$(viewName)/title/comp.style')
            .mapValue('$(viewName)/title/comp.fontFamily', 'family', { defaultValue: 'proxima-n-w01-reg' })
            .mapValue('$(viewName)/title/comp.fontSize', 'size', { minValue: 6, maxValue: 215, defaultValue: 22, formatter: Number })
            .mapValue('$(viewName)/title/comp.bold', 'style.bold', { defaultValue: false, formatter: boolean })
            .mapValue('$(viewName)/title/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
            .mapValue('$(viewName)/title/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
            .value()
          .mapFont('param_font_post-slider-post-list-descriptionFont')
            .mapTheme('$(viewName)/title/comp.style')
            .mapValue('$(viewName)/title/comp.fontFamily', 'family', { defaultValue: 'proxima-n-w01-reg' })
            .value()
          .value()
        .mapLogicParam('filter', 'param_boolean_postListWidgetIsFeatured', {
          formatter: (filter) => boolean(get(parseJson(filter), 'featured', 'false')),
        })
        .setValueParam('param_number_postListWidgetEntityCount', numberOfPosts)
        .setValueParam('param_number_postListWidgetPostsPerPage', numberOfPosts)
        .setValueParam('param_number_layout-post-list-pg-grid-margins', 0)
        .setValueParam('param_number_layout-post-list-pg-grid-layoutSpacing', 20)
        .setValueParam('param_number_post-post-list-borderWidth', 0)
        .setValueParam('param_boolean_blogMenu-isEnabled', false)
        .setValueParam('param_boolean_blog-isRecentPostsEnabled', false)
        .setValueParam('param_boolean_blog-isCommentsEnabled', false)
        .setValueParam('param_boolean_blog-isReadingTimeEnabled', false)
        .setValueParam('param_boolean_blog-isMoreOptionsMenuEnabled', false)
        .setValueParam('param_boolean_blog-isCommentCountEnabled', false)
        .setValueParam('param_boolean_blog-isViewCountEnabled', false)
        .setValueParam('param_boolean_blog-isAuthorNameEnabled', false)
        .setValueParam('param_boolean_blog-isAuthorBadgeEnabled', false)
        .setValueParam('param_boolean_blog-isAuthorPictureEnabled', false)
        .setValueParam('param_boolean_blog-isPostDescriptionEnabled', false)
        .setValueParam('param_boolean_blog-isLikeCountEnabled', false)
        .setColor('param_color_borderColor', 'color_12', 0)
        .mapFontColor('$(viewName)/title/$(key)', 'param_color_post-post-list-titleColor')
        .setColor('param_color_post-post-list-backgroundColor', 'color_11', 0)
        .mapFont(['param_font_post-post-list-titleFont', 'param_font_post-list-post-list-titleFont'])
          .mapTheme('$(viewName)/title/comp.style')
          .mapValue('$(viewName)/title/comp.fontFamily', 'family', { defaultValue: 'proxima-n-w01-reg' })
          .mapValue('$(viewName)/title/comp.fontSize', 'size', { minValue: 6, maxValue: 215, defaultValue: 22, formatter: Number })
          .mapValue('$(viewName)/title/comp.bold', 'style.bold', { defaultValue: false, formatter: boolean })
          .mapValue('$(viewName)/title/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
          .mapValue('$(viewName)/title/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
          .value()
        .mapFont(['param_font_post-post-list-descriptionFont', 'param_font_post-list-post-list-descriptionFont'])
          .mapTheme('$(viewName)/title/comp.style')
          .mapValue('$(viewName)/title/comp.fontFamily', 'family', { defaultValue: 'proxima-n-w01-reg' })
          .value()
        .setTpaData('categoryId', categoryId)
        .setTpaData('tagId', tagId)
        .setValueParam('param_boolean_blog-isPostPublishDateEnabled', false);

      return { componentDefinition: builder.build() };
    }
    case OLD_BLOG_APP_PART.SINGLE_POST: {
      const coordinates = await getCoordinatesRelativeToStructure(sdk, { id: serializedComponent.id, type: 'DESKTOP' });
      // prettier-ignore
      const builder = new BlogWidgetBuilder(serializedComponent, { widgetId: POST_WIDGET_ID, applicationId, fontMap, styleMap, widgetType: BlogWidgetBuilder.TPAMultisection })
      .mapFontColor('SinglePostMediaTop/title/$(key)', 'param_color_post-titleColor')
      .mapFontColor('SinglePostMediaTop/mediaText/$(key)', 'param_color_post-textColor')
      .mapFontColor('SinglePostMediaTop/tagsValue/$(key)', 'param_color_tags-textTag-textColor')
      .mapFontColor('SinglePostMediaTop/tagsValue/$(key)', 'param_color_tags-textTag-textHoverColor')
      .mapFont('param_font_post-titleFont')
        .mapTheme('SinglePostMediaTop/title/comp.style')
        .mapValue('SinglePostMediaTop/title/comp.fontFamily', 'family')
        .mapValue('SinglePostMediaTop/title/comp.fontSize', 'size', { minValue: 28, maxValue: 60, formatter: Number })
        .mapValue('SinglePostMediaTop/title/comp.bold', 'style.bold', { defaultValue: true, formatter: boolean })
        .mapValue('SinglePostMediaTop/title/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
        .mapValue('SinglePostMediaTop/title/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
        .value()
      .mapFont('param_font_post-pageFont')
        .mapTheme('SinglePostMediaTop/mediaText/comp.style')
        .mapValue('SinglePostMediaTop/mediaText/comp.fontFamily', 'family')
        .mapValue('SinglePostMediaTop/mediaText/comp.fontSize', 'size', { minValue: 12, maxValue: 28, formatter: Number })
        .value()
      .mapFont('param_font_tags-textTag-textFont')
        .mapTheme('SinglePostMediaTop/tagsValue/comp.style')
        .mapValue('SinglePostMediaTop/tagsValue/comp.fontFamily', 'family')
        .mapValue('SinglePostMediaTop/tagsValue/comp.fontSize', 'size', { minValue: 10, maxValue: 24, formatter: Number })
        .mapValue('SinglePostMediaTop/tagsValue/comp.bold', 'style.bold', { defaultValue: false, formatter: boolean })
        .mapValue('SinglePostMediaTop/tagsValue/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
        .mapValue('SinglePostMediaTop/tagsValue/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
        .value()
      .mapValueParam('SinglePostMediaTop/categories/comp.hidden', 'param_boolean_blog-isCategoryLabelsEnabled', { defaultValue: true, formatter: trueToFalse })
      .mapValueParam('SinglePostMediaTop/tagsBox/comp.hidden', 'param_boolean_blog-isTagsEnabled', { defaultValue: true, formatter: trueToFalse })
      .setValueParam('param_boolean_blogMenu-isEnabled', false)
      .setValueParam('param_boolean_blog-isRecentPostsEnabled', false)
      .setValueParam('param_boolean_blog-isCommentsEnabled', false)
      .setValueParam('param_boolean_blog-isReadingTimeEnabled', false)
      .setValueParam('param_boolean_blog-isMoreOptionsMenuEnabled', false)
      .setValueParam('param_boolean_blog-isMoreOptionsMenuEnabled', false)
      .setValueParam('param_boolean_blog-isCommentCountEnabled', false)
      .setValueParam('param_boolean_blog-isViewCountEnabled', false)
      .setValueParam('param_boolean_blog-isAuthorBadgeEnabled', false)
      .setValueParam('param_boolean_blog-isAuthorPictureEnabled', false)
      .setValueParam('param_boolean_blog-isAuthorNameEnabled', false)
      .setValueParam('param_boolean_blog-isPostUpdatedDateEnabled', false)
      .setValueParam('param_boolean_blog-isPostPublishDateEnabled', false)
      .mapValueParam('SinglePostMediaTop/vars/likeButtonEnabled', 'param_boolean_blog-isLikeCountEnabled', { defaultValue: true, formatter: boolean })
      .mapValueParam('SinglePostMediaTop/vars/newPinterestShareButtonIsVisible', 'param_boolean_pinterest-isEnabled', { defaultValue: true, formatter: boolean })
      .setValueParam('param_boolean_socialSharing-isEnabled', true)
      .mapValueParam('SinglePostMediaTop/vars/newFacebookShareButtonIsVisible', 'param_boolean_socialSharing-facebook', { defaultValue: true, formatter: boolean })
      .mapValueParam('SinglePostMediaTop/vars/newLinkedInShareButtonIsVisible', 'param_boolean_socialSharing-linked-in', { defaultValue: true, formatter: boolean })
      .mapValueParam('SinglePostMediaTop/vars/newTwitterShareButtonIsVisible', 'param_boolean_socialSharing-twitter', { defaultValue: true, formatter: boolean })
      .setValueParam('param_boolean_socialSharing-link', false)
      .mapThemeColor('SinglePostMediaTop/def_1/comp.style/brd', 'param_color_post-cardBorderColor', { defaultValue: ['color_15', 1] })
      .mapThemeColor('SinglePostMediaTop/def_1/comp.style/bg', 'param_color_post-cardBackgroundColor', { defaultValue: ['color_11', 1] })
      .mapThemeColor('SinglePostMediaTop/def_1/comp.style/bg', 'param_color_post-appBackgroundColor', { defaultValue: ['color_11', 1] })
      .mapThemeParam('SinglePostMediaTop/def_1/comp.style/brw', 'param_number_post-cardBorderWidth', { defaultValue: 0, formatter: parseInt })
      .setValueParam('param_number_tagLayout', `${TAG_LAYOUTS.text}`)
      .setValueParam('param_boolean_post-isTagLabelEnabled', true)
      .setCoordinates(coordinates);

      return {
        componentDefinition: builder.build(),
        props: { customId: await getUniqueSectionIdForPostPage(sdk) },
      };
    }
    case OLD_BLOG_APP_PART.CATEGORIES: {
      // prettier-ignore
      const builder = new BlogWidgetBuilder(serializedComponent, { widgetId: CATEGORY_MENU_WIDGET_ID, applicationId, fontMap, styleMap })
        .setValueParam('param_boolean_showAllPostsCategory', false)
        .setValueParam('param_number_textPaddingVertical', 2)
        .setValueParam('param_number_textPaddingHorizontal', 2)
        .mapValueParam('Categories/vars/showPostCount', 'param_boolean_showPostCount', {
          defaultValue: true,
          formatter: boolean,
        })
        .mapValueParam('Categories/vars/alignment', 'param_number_textAlignment', {
          defaultValue: 0,
          valueMap: { left: 0, center: 1, right: 2 },
        })
        .mapFont('param_font_textFont')
          .mapTheme('Categories/category/comp.style')
          .mapValue('Categories/category/comp.fontFamily', 'family')
          .mapValue('Categories/category/comp.fontSize', 'size', { maxValue: 176, formatter: Number })
          .mapValue('Categories/category/comp.bold', 'style.bold', { defaultValue: false, formatter: boolean })
          .mapValue('Categories/category/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
          .mapValue('Categories/category/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
          .value()
        .setColor('param_color_borderColor', 'color_12', 0)
        .setValueParam('param_number_borderWidth', 0)
        .mapFontColor('Categories/category/$(key)', 'param_color_textColor')
        .mapColor('Categories/category/comp.backgroundColor', 'param_color_backgroundColor', {
          opacity: 1,
          defaultValue: ['color_21', 0],
        });
      return { componentDefinition: builder.build() };
    }
    case OLD_BLOG_APP_PART.ARCHIVE: {
      // prettier-ignore
      const builder = new BlogWidgetBuilder(serializedComponent, { widgetId: ARCHIVE_WIDGET_ID, applicationId, fontMap, styleMap })
        .setValueParam('param_number_textPaddingVertical', 0)
        .setValueParam('param_number_textPaddingHorizontal', 0)
        .mapValueParam('ArchiveList/vars/showItemsCount', 'param_boolean_showPostCount', {
          defaultValue: true,
          formatter: boolean,
        })
        .mapValueParam('ArchiveList/vars/monthsToShow', 'param_number_monthsDisplayLimit', {
          defaultValue: 50,
          valueMap: { 'Infinity': 50, '24': 24, '12': 12 },
        })
        .mapFont('param_font_textFont')
          .mapTheme('ArchiveList/date/comp.style')
          .mapValue('ArchiveList/date/comp.fontFamily', 'family')
          .mapValue('ArchiveList/date/comp.fontSize', 'size', { maxValue: 176, formatter: Number })
          .mapValue('ArchiveList/date/comp.bold', 'style.bold', { defaultValue: false, formatter: boolean })
          .mapValue('ArchiveList/date/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
          .mapValue('ArchiveList/date/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
          .value()
        .setColor('param_color_borderColor', 'color_12', 0)
        .setValueParam('param_number_borderWidth', 0)
        .mapFontColor('ArchiveList/date/$(key)', 'param_color_textColor')
        .mapColor('ArchiveList/date/comp.backgroundColor', 'param_color_backgroundColor', {
          opacity: 1,
          defaultValue: ['color_21', 0],
        })
        .mapThemeColor('ArchiveList/lineSeparator/comp.style/brd', 'param_color_separatorColor')
        .mapThemeParam('ArchiveList/lineSeparator/comp.style/lnw', 'param_number_separatorWidth', {
          when: ['ArchiveList/itemSeparator/comp.hidden', 'false'],
          formatter: Number,
        });

      return { componentDefinition: builder.build() };
    }
    case OLD_BLOG_APP_PART.POSTS_LIST: {
      const { categoryId, tagId } = await resolvePostFilters(serializedComponent, instance, dryRun);
      // prettier-ignore
      const builder = new BlogWidgetBuilder(serializedComponent, {
        widgetId: POST_LIST_WIDGET_ID,
        applicationId,
        fontMap,
        styleMap,
      })
        .mapLogicParam('limit', 'param_number_postListWidgetEntityCount', { defaultValue: 10, formatter: Number })
        .mapLogicParam('filter', 'param_boolean_postListWidgetIsFeatured', {
          formatter: (filter) => boolean(get(parseJson(filter), 'featured', 'false')),
        })
        .mapViewName('param_number_layout-post-list-layoutType', {
          defaultValue: LAYOUT_LIST,
          formatter: String,
          valueMap: {
            PostsListMediaTop: LAYOUT_PG_GRID,
            PostsListMediaBottom: LAYOUT_PG_GRID,
            PostsListMediaLeft: LAYOUT_LIST,
            PostsList: LAYOUT_LIST,
          },
        })
        .mapValueParam('$(viewName)/media/comp.hidden', 'param_boolean_blog-isCoverImageEnabled', { defaultValue: true, formatter: trueToFalse })
        .setValueParam('param_boolean_blog-isAuthorNameEnabled', false)
        .setValueParam('param_boolean_blog-isViewCountEnabled', false)
        .setValueParam('param_boolean_blog-isCommentCountEnabled', false)
        .setValueParam('param_boolean_blog-isLikeCountEnabled', false)
        .setValueParam('param_boolean_blog-isReadingTimeEnabled', false)
        .setValueParam('param_boolean_blog-isPostDescriptionEnabled', false)
        .setValueParam('param_number_layout-post-list-pg-grid-imageCropType', 1)
        .setValueParam('param_number_layout-post-list-list-imageCropType', 1)
        .setValueParam('param_number_post-post-list-borderWidth', 0)
        .setColor('param_color_post-post-list-borderColor', 'color_12', 0)
        .setColor('param_color_post-post-list-backgroundColor', 'color_11', 0)
        .mapFontColor('$(viewName)/title/$(key)', 'param_color_post-post-list-titleColor')
        .mapFontColor('$(viewName)/date/$(key)', 'param_color_post-post-list-descriptionColor')
        .mapFont(['param_font_post-post-list-titleFont', 'param_font_post-pg-grid-post-list-titleFont', 'param_font_post-list-post-list-titleFont'])
          .mapTheme({ view: '$(viewName)', fieldId: 'title', key: 'comp.style', forType: 'Post' })
          .mapValue('$(viewName)/title/comp.fontFamily', 'family')
          .mapValue('$(viewName)/title/comp.fontSize', 'size', { minValue: 6, maxValue: 215, formatter: Number })
          .mapValue('$(viewName)/title/comp.bold', 'style.bold', { defaultValue: false, formatter: boolean })
          .mapValue('$(viewName)/title/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
          .mapValue('$(viewName)/title/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
          .value()
        .mapFont(['param_font_post-post-list-descriptionFont', 'param_font_post-pg-grid-post-list-descriptionFont', 'param_font_post-list-post-list-descriptionFont'])
          .mapTheme('$(viewName)/date/comp.style')
          .mapValue('$(viewName)/date/comp.fontFamily', 'family')
          .mapValue('$(viewName)/date/comp.fontSize', 'size', { minValue: 6, maxValue: 215, formatter: Number })
          .mapValue('$(viewName)/date/comp.bold', 'style.bold', { defaultValue: false, formatter: boolean })
          .mapValue('$(viewName)/date/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
          .mapValue('$(viewName)/date/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
          .value()
        .when('data.viewName', ['PostsListMediaTop', 'PostsListMediaBottom'])
          .mapValueParam('$(viewName)/vars/postSpacing', 'param_number_layout-post-list-pg-grid-layoutSpacing', { defaultValue: 22, formatter: Number })
          .setValueParam('param_number_layout-post-list-pg-grid-margins', 0)
          .setValueParam('param_number_layout-post-list-pg-grid-contentHeight', 160)
          .setValueParam('param_number_layout-post-list-pg-grid-margins', 0)
          .setValueParam('param_number_layout-post-list-pg-grid-sidesPadding', 0)
          .value()
        .when('data.viewName', 'PostsListMediaLeft')
          .setValueParam('param_number_layout-post-list-list-imageAlignment', PG_LAYOUT_IMAGE_ALIGNMENT_OPTIONS.LEFT_ALIGN)
          .mapValueParam('PostsListMediaLeft/vars/postSpacing', 'param_number_layout-post-list-list-layoutSpacing', { defaultValue: 22, formatter: Number })
          .setValueParam('param_number_layout-post-list-list-contentHeight', 60)
          .when('layout.height', value => value < 400)
            .setValueParam('param_number_layout-post-list-list-layoutSpacing', 5)
            .setValueParam('param_number_layout-post-list-list-contentHeight', 26)
            .setValueParam('param_number_layout-post-list-list-imageProportions', 35)
            .value()
          .setValueParam('param_number_layout-post-list-list-layoutPostSize', 1000)
          .value()
        .when('data.viewName', 'PostsList')
          .setValueParam('param_boolean_blog-isCoverImageEnabled', false)
          .setValueParam('param_number_layout-post-list-list-contentHeight', 26)
          .setValueParam('param_number_layout-post-list-list-sidesPadding', 12)
          .setValueParam('param_number_layout-post-list-list-layoutSpacing', 0)
          .when('layout.height', value => value < 400)
            .setValueParam('param_number_layout-post-list-list-layoutSpacing', 5)
            .setValueParam('param_number_layout-post-list-list-contentHeight', 26)
            .setValueParam('param_number_layout-post-list-list-imageProportions', 35)
            .value()
          .value()
        .setTpaData('categoryId', categoryId)
        .setTpaData('tagId', tagId)
        .setValueParam('param_boolean_blog-isPostPublishDateEnabled', false);

      return { componentDefinition: builder.build() };
    }
    case OLD_BLOG_APP_PART.FEATURED_POSTS: {
      // prettier-ignore
      const builder = new BlogWidgetBuilder(serializedComponent, {
        widgetId: POST_LIST_WIDGET_ID,
        applicationId,
        fontMap,
        styleMap,
      })
        .mapLogicParam('limit', 'param_number_postListWidgetEntityCount', { defaultValue: 10, formatter: Number })
        .setValueParam('param_boolean_postListWidgetIsFeatured', true)
        .mapViewName('param_number_layout-post-list-layoutType', {
          defaultValue: LAYOUT_LIST,
          formatter: String,
          valueMap: {
            TickerMediaTop: LAYOUT_SLIDER,
            TickerMediaBottom: LAYOUT_SLIDER,
            PostsListMediaTop: LAYOUT_PG_GRID,
            PostsListMediaBottom: LAYOUT_PG_GRID,
            PostsListMediaLeft: LAYOUT_LIST,
            PostsList: LAYOUT_LIST,
          },
        })
        .mapValueParam('$(viewName)/media/comp.hidden', 'param_boolean_blog-isCoverImageEnabled', { defaultValue: true, formatter: trueToFalse })
        .setValueParam('param_boolean_blog-isAuthorNameEnabled', false)
        .setValueParam('param_boolean_blog-isViewCountEnabled', false)
        .setValueParam('param_boolean_blog-isCommentCountEnabled', false)
        .setValueParam('param_boolean_blog-isLikeCountEnabled', false)
        .setValueParam('param_boolean_blog-isReadingTimeEnabled', false)
        .setValueParam('param_boolean_blog-isPostDescriptionEnabled', false)
        .setValueParam('param_number_post-post-list-borderWidth', 0)
        .setColor('param_color_post-post-list-borderColor', 'color_12', 0)
        .setColor('param_color_post-post-list-backgroundColor', 'color_11', 0)
        .mapFontColor('$(viewName)/title/$(key)', 'param_color_post-post-list-titleColor')
        .mapFontColor('$(viewName)/date/$(key)', 'param_color_post-post-list-descriptionColor')
        .mapFont(['param_font_post-post-list-titleFont', 'param_font_post-pg-grid-post-list-titleFont', 'param_font_post-list-post-list-titleFont', 'param_font_post-slider-post-list-titleFont'])
          .mapTheme('$(viewName)/title/comp.style')
          .mapValue('$(viewName)/title/comp.fontFamily', 'family')
          .mapValue('$(viewName)/title/comp.fontSize', 'size', { minValue: 6, maxValue: 215, formatter: Number })
          .mapValue('$(viewName)/title/comp.bold', 'style.bold', { defaultValue: false, formatter: boolean })
          .mapValue('$(viewName)/title/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
          .mapValue('$(viewName)/title/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
          .value()
        .mapFont(['param_font_post-post-list-descriptionFont', 'param_font_post-pg-grid-post-list-descriptionFont', 'param_font_post-list-post-list-descriptionFont', 'param_font_post-slider-post-list-descriptionFont'])
          .mapTheme('$(viewName)/date/comp.style')
          .mapValue('$(viewName)/date/comp.fontFamily', 'family')
          .mapValue('$(viewName)/date/comp.fontSize', 'size', { minValue: 6, maxValue: 215, formatter: Number })
          .mapValue('$(viewName)/date/comp.bold', 'style.bold', { defaultValue: false, formatter: boolean })
          .mapValue('$(viewName)/date/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
          .mapValue('$(viewName)/date/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
          .value()
        .when('data.viewName', ['TickerMediaTop', 'TickerMediaBottom'])
          .setValueParam('param_number_layout-post-list-slider-contentHeight', 160)
          .setValueParam('param_number_layout-post-list-slider-sidesPadding', 0)
          .mapValueParam('$(viewName)/TickerGallery/comp.autoplay', 'param_boolean_postListWidgetSliderAutoSlide', { defaultValue: true, formatter: boolean })
        .mapValueParam('$(viewName)/TickerGallery/comp.autoplayInterval', 'param_number_postListWidgetSliderPauseTime', { minValue: 1, maxValue: 10, defaultValue: 3, formatter: Number })
          .value()
        .when('data.viewName', ['PostsListMediaTop', 'PostsListMediaBottom'])
          .mapValueParam('$(viewName)/vars/postSpacing', 'param_number_layout-post-list-pg-grid-layoutSpacing', { defaultValue: 22, formatter: Number })
          .setValueParam('param_number_layout-post-list-pg-grid-margins', 0)
          .setValueParam('param_number_layout-post-list-pg-grid-contentHeight', 160)
          .setValueParam('param_number_layout-post-list-pg-grid-margins', 0)
          .setValueParam('param_number_layout-post-list-pg-grid-sidesPadding', 0)
          .value()
        .when('data.viewName', 'PostsListMediaLeft')
          .setValueParam('param_number_layout-post-list-list-imageAlignment', PG_LAYOUT_IMAGE_ALIGNMENT_OPTIONS.LEFT_ALIGN)
          .mapValueParam('PostsListMediaLeft/vars/postSpacing', 'param_number_layout-post-list-list-layoutSpacing', { defaultValue: 22, formatter: Number })
          .setValueParam('param_number_layout-post-list-list-contentHeight', 60)
          .setValueParam('param_number_layout-post-list-list-layoutPostSize', 1000)
          .when('layout.height', value => value < 400)
            .setValueParam('param_number_layout-post-list-list-layoutSpacing', 5)
            .setValueParam('param_number_layout-post-list-list-contentHeight', 26)
            .setValueParam('param_number_layout-post-list-list-imageProportions', 35)
            .value()
          .value()
        .when('data.viewName', 'PostsList')
          .setValueParam('param_boolean_blog-isCoverImageEnabled', false)
          .setValueParam('param_number_layout-post-list-list-contentHeight', 26)
          .setValueParam('param_number_layout-post-list-list-layoutSpacing', 0)
          .setValueParam('param_number_layout-post-list-list-sidesPadding', 12)
          .when('layout.height', value => value < 400)
            .setValueParam('param_number_layout-post-list-list-layoutSpacing', 5)
            .setValueParam('param_number_layout-post-list-list-contentHeight', 26)
            .setValueParam('param_number_layout-post-list-list-imageProportions', 35)
            .value()
          .value()
        .when('data.viewName', ['PostsListMediaTop', 'PostsListMediaBottom', 'PostsListMediaLeft', 'PostsList'])
          .setValueParam('param_number_layout-post-list-pg-grid-imageCropType', 1)
          .setValueParam('param_number_layout-post-list-list-imageCropType', 1)
          .value()
        .setValueParam('param_boolean_blog-isPostPublishDateEnabled', false);

      return { componentDefinition: builder.build() };
    }
    case OLD_BLOG_APP_PART.RSS_BUTTON: {
      // prettier-ignore
      const builder = new BlogWidgetBuilder(serializedComponent, { widgetId: RSS_BUTTON_WIDGET_ID, applicationId, fontMap, styleMap })
      .setValueParam('param_number_widget-iconBackgroundType', RSS_BUTTON_BACKGROUNDS.SQUARE)
      .setColor('param_color_widget-iconBackgroundColor', RSS_BUTTON_COLORS.ORANGE)
      .setColor('param_color_widget-iconColor', RSS_BUTTON_COLORS.WHITE)
      .setDimensions(toSquare(serializedComponent.layout))
      .when('data.image.uri', RSS_BUTTON_IMAGES.BLACK_CIRCLE)
        .setValueParam('param_number_widget-iconBackgroundType', RSS_BUTTON_BACKGROUNDS.CIRCLE)
        .setColor('param_color_widget-iconBackgroundColor', RSS_BUTTON_COLORS.BLACK)
        .setColor('param_color_widget-iconColor', RSS_BUTTON_COLORS.WHITE)
        .value()
      .when('data.image.uri', RSS_BUTTON_IMAGES.GRAY_CIRCLE)
        .setValueParam('param_number_widget-iconBackgroundType', RSS_BUTTON_BACKGROUNDS.CIRCLE)
        .setColor('param_color_widget-iconBackgroundColor', RSS_BUTTON_COLORS.GRAY)
        .setColor('param_color_widget-iconColor', RSS_BUTTON_COLORS.WHITE)
        .value()
      .when('data.image.uri', RSS_BUTTON_IMAGES.GRAY_ICON)
        .setValueParam('param_number_widget-iconBackgroundType', RSS_BUTTON_BACKGROUNDS.NONE)
        .setColor('param_color_widget-iconColorNoBackground', RSS_BUTTON_COLORS.GRAY)
        .setColor('param_color_widget-iconColor', RSS_BUTTON_COLORS.WHITE)
        .value()
      .when('data.image.uri', RSS_BUTTON_IMAGES.BLACK_ICON)
        .setValueParam('param_number_widget-iconBackgroundType', RSS_BUTTON_BACKGROUNDS.NONE)
        .setColor('param_color_widget-iconColorNoBackground', RSS_BUTTON_COLORS.BLACK)
        .setColor('param_color_widget-iconColor', RSS_BUTTON_COLORS.WHITE)
        .value();
      return { componentDefinition: builder.build() };
    }
    case OLD_BLOG_APP_PART.TICKER: {
      const { categoryId, tagId } = await resolvePostFilters(serializedComponent, instance, dryRun);
      // prettier-ignore
      const builder = new BlogWidgetBuilder(serializedComponent, { widgetId: POST_LIST_WIDGET_ID, applicationId, fontMap, styleMap })
      .setValueParam('param_number_layout-post-list-layoutType', `${LAYOUT_SLIDER}`)
      .setValueParam('param_number_layout-post-list-slider-contentHeight', 160)
      .setValueParam('param_number_layout-post-list-slider-sidesPadding', 0)
      .mapValueParam('$(viewName)/media/comp.hidden', 'param_boolean_blog-isCoverImageEnabled', { defaultValue: true, formatter: trueToFalse })
      .setValueParam('param_boolean_blog-isAuthorNameEnabled', false)
      .setValueParam('param_boolean_blog-isAuthorBadgeEnabled', false)
      .setValueParam('param_boolean_blog-isAuthorPictureEnabled', false)
      .setValueParam('param_boolean_blog-isViewCountEnabled', false)
      .setValueParam('param_boolean_blog-isCommentCountEnabled', false)
      .setValueParam('param_boolean_blog-isLikeCountEnabled', false)
      .setValueParam('param_boolean_blog-isReadingTimeEnabled', false)
      .setValueParam('param_boolean_blog-isPostDescriptionEnabled', false)
      .mapFont(['param_font_post-post-list-titleFont', 'param_font_post-slider-post-list-titleFont'])
        .mapTheme('$(viewName)/title/comp.style')
        .mapValue('$(viewName)/title/comp.fontFamily', 'family')
        .mapValue('$(viewName)/title/comp.fontSize', 'size', { minValue: 16, maxValue: 22, formatter: Number })
        .mapValue('$(viewName)/title/comp.bold', 'style.bold', { defaultValue: false, formatter: boolean })
        .mapValue('$(viewName)/title/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
        .mapValue('$(viewName)/title/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
        .value()
      .mapFont(['param_font_post-post-list-descriptionFont', 'param_font_post-slider-post-list-descriptionFont'])
        .mapTheme('$(viewName)/date/comp.style')
        .mapValue('$(viewName)/date/comp.fontFamily', 'family')
        .mapValue('$(viewName)/date/comp.fontSize', 'size', { minValue: 12, maxValue: 16, formatter: Number })
        .mapValue('$(viewName)/date/comp.bold', 'style.bold', { defaultValue: false, formatter: boolean })
        .mapValue('$(viewName)/date/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
        .mapValue('$(viewName)/date/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
        .value()
      .mapFontColor('$(viewName)/title/$(key)', 'param_color_post-post-list-titleColor')
      .mapFontColor('$(viewName)/date/$(key)', 'param_color_post-post-list-descriptionColor')
      .mapLogicParam('limit', 'param_number_postListWidgetEntityCount', { minValue: 1, maxValue: 60, defaultValue: 10, formatter: Number })
      .mapLogicParam('filter', 'param_boolean_postListWidgetIsFeatured', {
        formatter: (filter) => boolean(get(parseJson(filter), 'featured', 'false')),
      })
      .mapValueParam('$(viewName)/TickerGallery/comp.autoplay', 'param_boolean_postListWidgetSliderAutoSlide', { defaultValue: true, formatter: boolean })
      .mapValueParam('$(viewName)/TickerGallery/comp.autoplayInterval', 'param_number_postListWidgetSliderPauseTime', { minValue: 1, maxValue: 10, defaultValue: 3, formatter: Number })
      .setValueParam('param_number_post-post-list-borderWidth', 0)
      .setColor('param_color_post-post-list-borderColor', 'color_12', 0)
      .setColor('param_color_post-post-list-backgroundColor', 'color_11', 0)
      .setTpaData('categoryId', categoryId)
      .setTpaData('tagId', tagId)
      .setValueParam('param_boolean_blog-isPostPublishDateEnabled', false);

      return { componentDefinition: builder.build() };
    }
    case OLD_BLOG_APP_PART.TAG_CLOUD: {
      // prettier-ignore
      const builder = new BlogWidgetBuilder(serializedComponent, {
        widgetId: TAG_CLOUD_WIDGET_ID,
        applicationId,
        fontMap,
        styleMap,
      })
      .mapFont('param_font_tags-textTag-textFont')
        .mapValue('TagCloud/key/comp.fontFamily', 'family')
        .mapValue('TagCloud/key/comp.fontSize', 'size', { defaultValue: 14, formatter: Number })
        .mapValue('TagCloud/key/comp.bold', 'style.bold', { defaultValue: false, formatter: boolean })
        .mapValue('TagCloud/key/comp.italic', 'style.italic', { defaultValue: false, formatter: boolean })
        .mapValue('TagCloud/key/comp.underline', 'style.underline', { defaultValue: false, formatter: boolean })
        .value()
      .mapFontColor('TagCloud/key/$(key)', 'param_color_tags-textTag-textColor')
      .mapFontColor('TagCloud/key/$(key)', 'param_color_tags-textTag-textHoverColor')
      .setValueParam('param_number_tags-textTag-horizontalSpacing', 12)
      .setValueParam('param_number_tags-textTag-verticalSpacing', 12)
      .setValueParam('param_boolean_tags-showPostCount', false)
      .setValueParam('param_number_tags-orderTags', TAG_ORDERS.alphabetical)
      .setValueParam('param_number_tagLayout', `${TAG_LAYOUTS.text}`)
      .mapPath('props.direction', 'param_number_tags-textTag-alignment', {
        defaultValue: TAG_ALIGNMENTS.left,
        valueMap: { ltr: TAG_ALIGNMENTS.left, rtl: TAG_ALIGNMENTS.right },
      });

      return { componentDefinition: builder.build() };
    }
    case OLD_BLOG_APP_PART.RELATED_POSTS: {
      return null;
    }
    default:
      return { componentDefinition: getBox(serializedComponent.layout) };
  }
};

export default mapComponent;
